.star-rating {
    display: flex;
    align-items: start;
    justify-content: start;
}

/*.star {*/
/*    font-size: 2rem;*/
/*    cursor: pointer;*/
/*    color: gray;*/
/*    transition: color 0.2s;*/
/*}*/

.star .active {
    color: gold;
}

.star:hover {
    color: orange;
}

input[type=radio] {
    display: none;
}

.star {
    cursor: pointer;
}